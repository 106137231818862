import { getCoverageLineConfig } from '@common/config';
import { CoverageLine } from 'enums';
import { replaceLastCommaWithEnd } from './messages-utils';

interface coverageLinesTextProps {
  replaceLastCommaWithEnd?: boolean;
}
export function getCoverageLinesText(coverages?: CoverageLine[], additionalProps?: coverageLinesTextProps) {
  const coverageLinesText =
    coverages && coverages.length > 0
      ? coverages.map((coverage) => getCoverageLineConfig(coverage).text).join(', ')
      : '';
  if (additionalProps?.replaceLastCommaWithEnd) {
    return replaceLastCommaWithEnd(coverageLinesText);
  }
  return coverageLinesText;
}
