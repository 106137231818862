import { convertCoverageLinesTextToEnum } from '@common/utils';
import { isArray, isEmpty, isEqual } from 'lodash';
import { darken, Highlight, Stack, SxProps, Theme, Tooltip, Typography, TypographyProps } from '@common-components';
import { Warning } from '@icons';

import { isSubmissionTerminated, SubmissionStatus, SubmissionTerminationStatus, UserStatus } from 'enums';
import { useCurrentUser, useMutateSubmission } from 'hooks';
import { PartialSubmission, User } from 'types';
import {
  getCoverageLinesText,
  getDisplayDate,
  getTimezoneLessDisplayDate,
  hasMinutesPassed,
  isMoreThan24HoursAgo,
  showFastTrackIndication,
  timeSince,
  truncateMultiLineText,
  uniteStyles,
  userFullName,
} from 'utils';
import { Features, isFeatureEnabled } from 'utils/features-config';
import IconBadge from 'components/IconBadge';
import {
  activeTabUrlParamValues,
  BrokerUrlParams,
  generatePortalSubmissionPath,
  generateSubmissionPath,
} from 'broker/broker-routes';
import { QuoteCoverageLines } from 'broker/components/common/QuoteCoverageLines';
import FastTrackIconButton from 'broker/components/FastTrackIconButton';
import MenuBadge from 'broker/components/MenuBadge';
import {
  submissionStatusConfig,
  visibleSubmissionStatuses,
} from 'broker/configuration-mappers/submission-status-config';
import { RouteKey } from 'broker/enums';
import { useNavigate } from 'broker/hooks';
import useSingleFieldOptimisticUpdate from 'broker/hooks/useSingleFieldOptimisticUpdate';
import { useSubmissionTerminationDetails } from 'broker/hooks/useSubmissionTerminationDetails';
import { SUBMISSIONS_VIEW_COLUMNS, SubmissionsAssignee, submissionsViewColumnsConfig } from './config';
import CopilotIndicator from './CopilotIndicator';
import Pinner from './Pinner';
import SubmissionAssigneeList from './SubmissionAssigneeList';
import SubmissionTableColumn from './SubmissionTableColumn';
import SubmissionTableReminder from './SubmissionTableReminder';
import { SubmissionViewMode } from './utils';

export default function SubmissionTableItem({
  submission,
  isPinned,
  togglePin,
  endUser,
  submissionsAssignee,
  onAssigneeChange,
  users,
  viewMode,
  searchInput = '',
  onTerminateClick,
}: {
  submissionsAssignee: SubmissionsAssignee;
  endUser: User | null;
  onAssigneeChange: (submissionId: string, userId: string) => Promise<void>;
  submission: PartialSubmission;
  isPinned: boolean;
  togglePin: (id: string) => void;
  users: User[];
  viewMode: SubmissionViewMode;
  searchInput?: string;
  onTerminateClick: (submission: PartialSubmission, targetStatus: SubmissionTerminationStatus) => void;
}) {
  const extractedData = submission.submissionExtractedData?.extractedData;
  const isSubmissionBox = viewMode === SubmissionViewMode.PreviewBox;
  const isInsuredNameExtracted = !!(
    extractedData?.insuredName?.value && submission.insuredName === extractedData?.insuredName?.value
  );
  const isCoverageLinesExtracted =
    isArray(extractedData?.coverageLines?.value) &&
    !isEmpty(extractedData?.coverageLines?.value) &&
    isEqual(submission.coverageLines, convertCoverageLinesTextToEnum(extractedData!.coverageLines!.value!));
  const { me } = useCurrentUser();
  const { updateSubmission } = useMutateSubmission();
  const updateSubmissionStatus = async (status: SubmissionStatus) => {
    const updatedSubmission = await updateSubmission.mutateAsync({
      id: submission.id,
      data: { status },
    });

    return updatedSubmission?.status;
  };
  const { value: selectedStatus, onChange: onStatusChange } = useSingleFieldOptimisticUpdate<SubmissionStatus>({
    serverValue: submission.status,
    apiUpdate: updateSubmissionStatus,
  });

  const ownOnStatusChange = async (status: SubmissionStatus) => {
    if (isSubmissionTerminated(status)) {
      onTerminateClick(submission, status as SubmissionTerminationStatus);
    } else {
      await onStatusChange(status);
    }
  };

  const terminationDetails = useSubmissionTerminationDetails({ submission, reasonOnly: true });

  const navigate = useNavigate();

  const submissionConfig = submissionsViewColumnsConfig(viewMode);
  const { isCopilotEnabled } = submission;

  const onSubmissionClick = () => {
    if (viewMode === SubmissionViewMode.Retailer) {
      navigate(
        {
          pathname: generatePortalSubmissionPath(submission.id),
        },
        {
          routeKey: RouteKey.WorkspaceTab,
          state: undefined,
        },
      );
      return;
    }

    navigate(
      {
        pathname: generateSubmissionPath(submission.id),
        search: submission.mostUrgentNotification
          ? `?${BrokerUrlParams.ACTIVE_TAB}=${activeTabUrlParamValues.TASKS}`
          : undefined,
      },

      {
        routeKey: RouteKey.WorkspaceTab,
        state: undefined,
      },
    );
  };

  const terminationReasonTooltip = viewMode === SubmissionViewMode.General ? terminationDetails : '';
  const submissionStatusProps = selectedStatus
    ? {
        bgColor: submissionStatusConfig[selectedStatus].bgColor,
        text: submissionStatusConfig[selectedStatus].text(viewMode),
      }
    : undefined;

  const isUnknownSender = submission.user?.status === UserStatus.PendingReview;

  const getExtractedNotExtractedTypographyProps = (
    isExtracted: boolean,
    variant: TypographyProps['variant'] = 'body2',
  ): Pick<TypographyProps, 'variant' | 'color'> =>
    isExtracted && isSubmissionBox
      ? { variant: `${variant}Bold` as TypographyProps['variant'], color: 'green.600' }
      : {
          variant: variant as TypographyProps['variant'],
        };

  const getExtractedNotExtractedSxProps = (isExtracted: boolean): SxProps<Theme> =>
    isExtracted && isSubmissionBox ? { span: { bgcolor: 'green.100' } } : {};

  // TODO: Increase the time after prompts are implemented (CAP-5122)
  const disabledSubmission =
    submission.status === SubmissionStatus.Processing &&
    (!hasMinutesPassed(submission.createdAt, 1) || submission.isFastTrack);

  return (
    <Stack
      px={2}
      gap={1}
      direction="row"
      alignItems="stretch"
      onClick={disabledSubmission ? undefined : onSubmissionClick}
      sx={{
        cursor: disabledSubmission ? 'not-allowed' : 'pointer',
        ':hover': {
          bgcolor: (theme) => darken(theme.palette.common.white, 0.02),
          '& .edit-icon': {
            visibility: 'visible',
          },
        },
        ':not(:last-child)': {
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        },
      }}
    >
      <SubmissionTableColumn
        column={SUBMISSIONS_VIEW_COLUMNS.Pinned}
        viewMode={viewMode}
        my={2}
        justifyContent="center"
      >
        <Pinner isPinned={isPinned} addPinnedRow={togglePin} submissionId={submission.id} />
      </SubmissionTableColumn>
      <SubmissionTableColumn
        column={SUBMISSIONS_VIEW_COLUMNS.Received}
        viewMode={viewMode}
        overflow="hidden"
        justifyContent="center"
        alignItems="flex-start"
        py={3}
      >
        <Stack direction="row" gap={1} justifyContent="center">
          {submission.createdAt && isMoreThan24HoursAgo(submission.createdAt) && (
            <IconBadge size="extraSmall" IconComponent={Warning} bgcolor="warning.main" />
          )}
          <Typography variant="body2" noWrap>
            {submission.createdAt && timeSince(submission.createdAt)}
          </Typography>
        </Stack>
      </SubmissionTableColumn>
      <SubmissionTableColumn
        column={SUBMISSIONS_VIEW_COLUMNS.Contacts}
        viewMode={viewMode}
        justifyContent="center"
        overflow="hidden"
      >
        <Stack>
          <Tooltip
            tooltipContent={
              <Highlight
                searchWords={[searchInput]}
                textToHighlight={!isEmpty(submission.user) ? userFullName(submission.user) : ''}
              />
            }
          >
            <Typography
              variant={isUnknownSender ? 'body2Bold' : 'body2'}
              component="div"
              color={isUnknownSender ? 'error.main' : 'inherit'}
              sx={{ ...truncateMultiLineText(2) }}
            >
              <Highlight
                searchWords={[searchInput]}
                textToHighlight={!isEmpty(submission.user) ? userFullName(submission.user) : ''}
              />
            </Typography>
          </Tooltip>
          <Typography variant="caption" color="textSecondary" noWrap sx={truncateMultiLineText(1)}>
            {submission.organizationName}
          </Typography>
        </Stack>
      </SubmissionTableColumn>
      <SubmissionTableColumn
        column={SUBMISSIONS_VIEW_COLUMNS.InsuredName}
        viewMode={viewMode}
        justifyContent="center"
        overflow="hidden"
      >
        <Typography
          {...getExtractedNotExtractedTypographyProps(isInsuredNameExtracted)}
          overflow="hidden"
          sx={uniteStyles({ ...truncateMultiLineText(3) }, getExtractedNotExtractedSxProps(isInsuredNameExtracted))}
        >
          <Highlight
            searchWords={[searchInput]}
            textToHighlight={!isEmpty(submission.insuredName) ? submission.insuredName! : ''}
          />
        </Typography>
        <Tooltip tooltipContent={QuoteCoverageLines({ coverageLines: submission.coverageLines || [], searchInput })}>
          <Typography
            {...getExtractedNotExtractedTypographyProps(isCoverageLinesExtracted, 'caption')}
            component="div"
            sx={uniteStyles(
              {
                display: 'inline-block',
                ...truncateMultiLineText(2),
              },
              getExtractedNotExtractedSxProps(isCoverageLinesExtracted),
            )}
          >
            <Highlight
              searchWords={[searchInput]}
              textToHighlight={
                !isEmpty(getCoverageLinesText(submission.coverageLines))
                  ? getCoverageLinesText(submission.coverageLines)
                  : ''
              }
            />
          </Typography>
        </Tooltip>
      </SubmissionTableColumn>
      <SubmissionTableColumn column={SUBMISSIONS_VIEW_COLUMNS.Status} viewMode={viewMode} stopPropagation>
        <Stack flex={1} minWidth={120}>
          <MenuBadge
            title={submissionStatusProps ? submissionStatusProps.text : ''}
            subtitle={submissionStatusConfig[submission.status].subtitle?.(disabledSubmission) || ''}
            subtitleColor={submissionStatusConfig[submission.status].subtitleColor}
            tooltipTitle={terminationReasonTooltip}
            inactive={!!(submissionStatusProps && me && !isFeatureEnabled(Features.EditSubmission, me))}
            processing={submissionStatusConfig[submission.status].inProgressStatus}
            menuGroups={[
              {
                key: 'submission-status-menu',
                items: visibleSubmissionStatuses.map((status) => ({
                  label: submissionStatusConfig[status as SubmissionStatus].text(viewMode),
                  onClick: async () => {
                    await ownOnStatusChange(status as SubmissionStatus);
                  },
                })),
              },
            ]}
            color={submissionStatusProps ? submissionStatusProps.bgColor : ''}
          />
        </Stack>
      </SubmissionTableColumn>
      <SubmissionTableColumn column={SUBMISSIONS_VIEW_COLUMNS.DueDate} viewMode={viewMode}>
        <Typography
          display="flex"
          alignItems="center"
          variant="body2"
          noWrap
          flex={submissionConfig[SUBMISSIONS_VIEW_COLUMNS.DueDate].flex}
        >
          {submission.dueDate && getTimezoneLessDisplayDate(submission.dueDate)}
        </Typography>
      </SubmissionTableColumn>

      <SubmissionTableColumn
        column={SUBMISSIONS_VIEW_COLUMNS.Assignee}
        viewMode={viewMode}
        justifyContent="center"
        overflow="hidden"
      >
        <Stack direction="row" gap={0.5}>
          {showFastTrackIndication(submission) && <FastTrackIconButton />}
          <SubmissionAssigneeList
            submissionId={submission.id}
            usersList={users}
            selectedUser={submissionsAssignee[submission.id]}
            onAssigneeChange={onAssigneeChange}
            viewOnlyMode={viewMode === SubmissionViewMode.Retailer}
          />
        </Stack>
      </SubmissionTableColumn>
      <SubmissionTableColumn column={SUBMISSIONS_VIEW_COLUMNS.Reminder} viewMode={viewMode} overflow="hidden">
        <SubmissionTableReminder
          urgentNotificationsCount={submission.urgentNotificationsCount}
          mostUrgentNotification={submission.mostUrgentNotification}
        />
      </SubmissionTableColumn>
      <SubmissionTableColumn
        column={SUBMISSIONS_VIEW_COLUMNS.LastUpdated}
        viewMode={viewMode}
        justifyContent="center"
        overflow="hidden"
        alignItems="flex-end"
      >
        <Typography variant="body2" noWrap>
          {submission.updatedAt && getDisplayDate(submission.updatedAt)}
        </Typography>
      </SubmissionTableColumn>
      <SubmissionTableColumn my={2} justifyContent="center" column={SUBMISSIONS_VIEW_COLUMNS.Menu} viewMode={viewMode}>
        <CopilotIndicator
          submission={submission}
          copilotSettings={!!endUser?.organization.copilotSettings}
          isCopilotEnabled={isCopilotEnabled}
          flex={submissionConfig[SUBMISSIONS_VIEW_COLUMNS.Menu].flex}
          display={submissionConfig[SUBMISSIONS_VIEW_COLUMNS.Menu].showForModes.includes(viewMode) ? 'flex' : 'none'}
        />
      </SubmissionTableColumn>
    </Stack>
  );
}
